<template>
  <div class="w-full">
    <div class="w-full grid md:gap-x-10 grid-cols-1 md:grid-cols-12 items-end">
      <div
        class="col-span-5 w-full flex flex-col justify-start items-start mb-2"
      >
        <span class="text-sm text-gray-800 font-bold">Desde</span>
        <date-picker :default="start_date" @update="updateStartDate" />
      </div>
      <div
        class="col-span-5 w-full flex flex-col justify-start items-start mb-2"
      >
        <span class="text-sm text-gray-800 font-bold">Hasta</span>
        <date-picker :default="end_date" @update="updateEndDate" />
      </div>
      <div
        class="col-span-2 w-full flex justify-end items-center mb-4 md:mb-2 mt-2 md:mt-0"
      >
        <button
          type="button"
          @click="getPaymentData"
          class="bg-green-600 hover:bg-green-700 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium"
        >
          Filtrar por fecha
        </button>
      </div>
    </div>
    <input-search v-model="search" class="mt-2" />
    <table-component
      :loading="loading"
      :head="[
        { key: 'code', label: 'Código reserva' },
        { key: 'name', label: 'Nombre paciente' },
        { key: 'date', label: 'fecha atención' },
        { key: 'time', label: 'hora atención' },
        { key: 'amount', label: 'ingreso por prestación' },
      ]"
      :body="body"
    />
    <div class="flex justify-end items-center mt-8">
      <span class="text-xl font-bold text-gray-600"
        >Total ingresos: {{ chileanPeso(income) }}</span
      >
    </div>
  </div>
</template>

<script>
import PaymentService from '@/services/paymentService';
import moment from 'moment';
import Table from '@/components/app/Table.vue';
import Datepicker from '@/components/form/Datepicker.vue';
import InputSearch from '@/components/form/InputSearch.vue';

export default {
  name: 'Payments',
  components: {
    'table-component': Table,
    'date-picker': Datepicker,
    'input-search': InputSearch,
  },
  data() {
    return {
      data: [],
      loading: true,
      start_date: `1/${moment().month()}/${moment().year()}`,
      end_date: `${moment().daysInMonth()}/${moment().month()}/${moment().year()}`,
      search: '',
    };
  },
  created() {
    this.getPaymentData();
  },
  methods: {
    getPaymentData() {
      PaymentService.getIncomePayments({
        start: this.addMonth(this.start_date),
        end: this.addMonth(this.end_date),
      })
        .then(response => {
          this.data = response.data.income.reverse();
        })
        .catch(() => {
          // error
        });
    },
    addMonth(date) {
      const [day, month, year] = date.split('/');
      return `${day}/${parseInt(month) + 1}/${year}`;
    },
    chileanPeso(price) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(price).replace(/,/g, '.');
    },
    months() {
      moment.locale('es');
      return moment.months();
    },
    years() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - parseInt(back / 2) + i + 1
        );
      };

      return years(10);
    },
    updateStartDate(date) {
      this.start_date = date;
    },
    updateEndDate(date) {
      this.end_date = date;
    },
  },
  computed: {
    body() {
      let temp = this.data.map(e => {
        return {
          code: {
            label: e.code,
            class: 'text-green-600',
          },
          name: {
            label: e.name,
            class: 'text-gray-700',
          },
          date: {
            label: e.date,
            class: 'text-gray-700',
          },
          time: {
            label: e.time,
            class: 'text-gray-700',
          },
          amount: {
            label: this.chileanPeso(e.amount),
            class: 'text-gray-700',
          },
        };
      });

      if (this.search != '') {
        temp = temp.filter(e => {
          return e.name.label.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return temp;
    },
    income() {
      let temp = this.data.map(e => {
        return {
          amount: e.amount,
          name: e.name,
        };
      });

      if (this.search != '') {
        temp = temp.filter(e => {
          return e.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return temp.reduce(
        (partialSum, element) => partialSum + element.amount,
        0
      );
    },
  },
};
</script>
